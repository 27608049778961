@if (showInfoBanner) {
    <div class="w-full bg-red-600 text-white text-center z-50 overflow-hidden">
        <div id="carouselDarkVariant"
             class="relative"
             data-te-carousel-init
             data-te-ride="carousel"
             data-te-interval="5000">
            <!-- Carousel items -->
            <div class="relative text-sm lg:text-lg h-10 lg:h-12 w-full overflow-hidden after:clear-both after:block after:content-['']">
                <!-- First item -->
                <div class="relative mt-[0.6rem] lg:mt-[0.55rem] float-left -mr-[100%] w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
                     data-te-carousel-fade
                     data-te-carousel-item
                     data-te-carousel-active>
                    <p>{{ slides[0].message }}
                        @if (slides[0].link) {
                            <app-link class="inline-block underline" [link]="slides[0].link"/>
                        }
                    </p>
                </div>
                @for (slide of slides; track slide; ) {
                    @if ($index !== 0) {
                        <div class="relative mt-[0.6rem] lg:mt-[0.55rem] float-left -mr-[100%] w-full !transform-none opacity-0 transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
                             data-te-carousel-fade
                             data-te-carousel-item>
                            <p>{{ slide.message }}
                                @if (slide.link) {
                                    <app-link class="inline-block underline" [link]="slide.link"/>
                                }
                            </p>
                        </div>
                    }
                }
            </div>

            <!-- Carousel controls - prev item-->
            <button class="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                    type="button"
                    data-te-target="#carouselDarkVariant"
                    data-te-slide="prev">
    <span class="inline-block dark:grayscale">
      <svg xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24"
           stroke-width="1.5"
           stroke="currentColor"
           class="h-6 w-6">
        <path stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"/>
      </svg>
    </span><span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Previous</span></button>
            <!-- Carousel controls - next item-->
            <button class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                    type="button"
                    data-te-target="#carouselDarkVariant"
                    data-te-slide="next">
    <span class="inline-block dark:grayscale">
      <svg xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24"
           stroke-width="1.5"
           stroke="currentColor"
           class="h-6 w-6">
        <path stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"/>
      </svg>
    </span><span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Next</span></button>
        </div>
    </div>
}