import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "@auth0/auth0-angular";
import {environment} from "../../environments/environment";
import {Carousel, initTE} from "tw-elements";

@Component({
    selector: 'app-brand-ambassador',
    templateUrl: './brand-ambassador.component.html',
    styleUrl: './brand-ambassador.component.css'
})
export class BrandAmbassadorComponent implements OnInit {

    toastMessage: string = '';

    constructor(
        private titleService: Title,
        private route: ActivatedRoute,
        public auth: AuthService,) {
    }

    ngOnInit(): void {
        initTE({Carousel});
        this.route.data.subscribe(data => {
            if (data['title']) {
                this.titleService.setTitle(data['title']);
            }
        });
    }

    protected readonly environment = environment;
}
