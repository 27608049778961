<div class="bg-gray-200">
    <div @slideWrapper
         class="pt-32 lg:pt-52 pb-14 w-4/5 lg:w-3/5 mx-auto text-gray-800 text-center font-medium text-base lg:text-2xl leading-loose space-y-4 lg:space-y-8">
        <p @slideUp class="text-2xl lg:text-4xl font-bold pb-3 lg:pb-5">Непокор.мк - Непокорот е слобода!</p>
        <p @slideUp>Ние сме тука да ви ги обезбедиме најдобрите производи со патриотски македонски мотиви, кои ги
            носат симболите на
            нашата богата историја и нашиот непокорен дух. Секој наш производ е изработен со љубов и почит кон нашиот
            народ и култура.</p>
        <p @slideUp>Придружете ни се и станете дел од движењето Непокор.мк - симбол на македонскиот патриотизам и
            непокор.</p>
        <p @slideUp>За дополнителни информации, контактирајте не на <a class="hover:text-[#3b71ca] hover:underline"
                                                                       href="mailto:nepokormacedonia@gmail.com">nepokormacedonia&#64;gmail.com</a>
            или преку нашата <a class="hover:text-[#3b71ca] hover:underline" [href]="environment.navigation.contactUs">контакт
                форма.</a></p>
        <p @slideUp>Ви благодариме за поддршката! Заедно, непокорно и бескомпромисно, го браниме македонскиот
            идентитет!</p>
        <app-text @slideUp
                  class="pt-10 mb-[-15px] lg:pt-14 text-gray-800 font-medium text-base lg:text-2xl leading-loose uppercase text-center"
                  value="СЛЕДИ Нè"/>
        <!-- Social Media Icons -->
        <div @slideWrapper class="flex justify-center relative space-x-3">
            @for (iconLink of iconLinks; track iconLink) {
                <app-secondary-button @slideUp [class]="'fill-black'"
                                      [icon]="iconLink.icon"
                                      (click)="openExternalLink(iconLink.link)"></app-secondary-button>
            }
        </div>
    </div>
</div>
<app-footer></app-footer>



