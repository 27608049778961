@if (blogs.length > 0) {
    <div id="latestBlogPosts" [class]="'h-fit pb-16 pt-8 lg:pt-12'">
        <div [@slideInFromBottom]="showAnimation ? 'visible' : 'hidden'">
            <app-text class="text-center text-3xl lg:text-5xl font-black"
                      [value]="'Најнови блог постови'"></app-text>
        </div>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-y-4 lg:gap-y-6 gap-3 lg:gap-6 pt-5 lg:pt-10 px-3 lg:px-10"
             [ngClass]="blogs.length === 0 ? 'h-screen' : ''">
            @for (blog of blogs; track blog) {
                @if (blog.isPublished) {
                    <div class="duration-200 transform lg:hover:scale-105 transition-transform bg-neutral-200 border-neutral-400 flex flex-col rounded-lg text-surface dark:bg-surface-dark dark:text-white sm:shrink-0 sm:grow sm:basis-0"
                         [ngClass]="blog.mainImage? 'border-x-2 border-b-2' : 'border-2'">
                        <button data-te-ripple-init
                                data-te-ripple-color="light"
                                (click)="goToUrl(router, 'blogs/' + (blog?.slug ? blog?.slug + '-' + blog?.id : blog?.id))">
                            @if (blog.mainImage) {
                                <div class="w-full h-fit overflow-hidden relative">
                                    <img class="rounded-t-lg border-4 border-[#D12228] z-50 object-cover object-center w-full h-full"
                                         src="{{blog.mainImage}}"
                                         alt="Blog image"
                                    />
                                </div>
                            }
                        </button>
                        <div class="p-2 lg:p-6 text-sm">
                            <app-text [ngClass]="blog.mainImage? 'lg:text-xl': 'lg:text-2xl'"
                                      class="min-h-16 mb-2 font-medium leading-tight text-gray-800"
                                      [value]="blog.title"></app-text>
                            <app-text class="lg:mb-4 text-xs lg:text-base min-h-24"
                                      [value]="truncateString(blog.summary, 110)"></app-text>
                        </div>
                        <button data-te-ripple-init
                                data-te-ripple-color="light"
                                class="underline text-xs lg:text-base text-left px-2 lg:px-6 text-gray-900 pb-2"
                                (click)="goToUrl(router, 'blogs/' + (blog?.slug ? blog?.slug + '-' + blog?.id : blog?.id))">
                            Прочитај повеќе
                        </button>
                        <div class="mt-auto border-t-2 border-neutral-300 px-2 lg:px-6 py-1 lg:py-3 text-center text-surface/75 dark:border-white/10 dark:text-neutral-300">
                            <div class="flex justify-start text-xs lg:text-base">
                                <a [href]="blog.author.link">
                                    @if (blog.author.image) {
                                        <app-image
                                                [src]="blog.author.image"
                                                class="w-6 lg:w-9 rounded-full shadow-lg cursor-pointer"
                                                [alt]="blog.author.image"
                                        />
                                    }
                                </a>
                                <p class="my-auto lg:pl-1">
                                    Од <span class="text-gray-900 hover:underline cursor-pointer"> <a
                                        [href]="blog.author.link">{{ blog.author.name }}</a></span> |
                                    {{ formatDate(datePipe, blog.publishDate!) }}
                                </p>
                            </div>
                            @if (auth.user$ | async; as user) {
                                @if (user.email === environment.admin) {

                                    <app-secondary-button [value]="'Уреди'"
                                                          [class]="'pt-2 flex mx-auto text-xs lg:text-base'"
                                                          (click)="goToUrl(router, 'blogs/' + blog?.id + '/edit')"
                                    ></app-secondary-button>
                                }
                            }
                        </div>
                    </div>
                }
            }
        </div>
        <div class="mx-auto md:w-1/2 pt-10">
            <app-secondary-button
                    (click)="goToUrl(router, '/blogs')" class="flex w-2/3 md:w-80 mx-auto"
                    [value]="'Види ги сите блог постови'"></app-secondary-button>
        </div>
    </div>
}