import {Component, Input, OnInit} from '@angular/core';
import {Carousel, initTE,} from "tw-elements";
import {LinkModule} from "../link/link.module";
import {environment} from "../../environments/environment";
import {InfoBannerSlide} from "../../models/slide";

@Component({
    selector: 'app-info-banner',
    standalone: true,
    imports: [
        LinkModule
    ],
    templateUrl: './info-banner.component.html',
    styleUrl: './info-banner.component.css'
})
export class InfoBannerComponent implements OnInit {

    @Input()
    showInfoBanner: boolean = true;

    slides: InfoBannerSlide[] = [
        {
            message: "Попуст до 30% на",
            link: {
                text: "селектирани производи",
                route: environment.navigation.shop + '/on-discount'
            },
        },
        {
            message: "Стани непокор",
            link: {
                text: "бренд амбасадор",
                route: environment.navigation.brandAmbassador
            },

        },
        {
            message: "Бесплатна достава за нарачки над 1400 ден."
        }
    ];

    ngOnInit(): void {
        initTE({Carousel});
    }

}